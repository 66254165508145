import React from "react";
import { Collapse } from "antd";

type FAQItem = {
    question: string;
    answer: string;
};

const generateFAQ = (
    data: FAQItem[],
    params: Record<string, string | number | null>
) => {
    return data.map((item, index) => ({
        key: String(index + 1),
        label: item.question.replace(
            /{(\w+)}/g,
            (_, key) => String(params[key]) || ""
        ),
        children: item.answer.replace(
            /{(\w+)}/g,
            (_, key) => String(params[key]) || ""
        ),
    }));
};

const FAQ: React.FC<{
    location?: string | null;
    serviceType?: string | null;
    priceFrom?: number;
}> = ({
    location = "в вашем городе",
    serviceType = "на дом",
    priceFrom = 5000,
}) => {
        const faqData: FAQItem[] = [
            {
                question: `Кто такой Дед Мороз ?`,
                answer: `Дед Мороз — сказочный персонаж, который дарит радость и подарки детям на Новый год. Его сопровождает Снегурочка, а вместе они создают атмосферу настоящего волшебства.`,
            },
            {
                question: `Как заказать Деда Мороза {location}?`,
                answer: `Созвонитесь напрямую с исполнителем, чтобы согласовать все детали.`,
            },
            {
                question: `Какие подарки могут быть у Деда Мороза?`,
                answer: `Подарки могут быть самыми разными — от сладостей до тематических сувениров. Вы также можете заранее передать свои подарки исполнителю, чтобы он вручил их лично {location}.`,
            },
            {
                question: `Какова стоимость услуги ?`,
                answer: `Стоимость услуг начинается от {priceFrom} рублей. Цена зависит от сценария, продолжительности программы и вашего местоположения {location}.`,
            },
            {
                question: `Как выбрать подходящего Деда Мороза?`,
                answer: `Посмотрите профили исполнителей на нашем сайте. Мы предоставляем рейтинги, отзывы клиентов и фотографии, чтобы вам было легче выбрать деда мороза {location}.`,
            },
            {
                question: `Есть ли у вас Деды Морозы {location}?`,
                answer: `Наши исполнители работают по всей стране! Укажите ваш город при поиске, и мы покажем доступных Дедов Морозов в вашем регионе .`,
            },
            {
                question: `Как проходит встреча с Дедом Морозом ?`,
                answer: `Встреча включает в себя развлекательную программу: поздравления, игры, песни и вручение подарков. Каждый сценарий индивидуален и обсуждается заранее {location}.`,
            },
            {
                question: `Можно ли заказать Деда Мороза для взрослых {location}?`,
                answer: `Конечно! Наши исполнители проводят вечеринки, корпоративы и даже тематические квесты для взрослых {location}. Сделайте свой праздник уникальным!`,
            },
            {
                question: `Как мне отменить заказ?`,
                answer: `Если вам нужно отменить заказ, свяжитесь с исполнителем через нашу платформу {location}. Постарайтесь сделать это заранее, чтобы избежать неудобств.`,
            },
            {
                question: `Можно ли попросить особые пожелания для Деда Мороза {location}?`,
                answer: `Да, укажите ваши пожелания при оформлении заказа или обсудите их с исполнителем. Например, это может быть вручение подарка или проведение уникальной игры {location}.`,
            },
        ];

        const faqItems = generateFAQ(faqData, { location, serviceType, priceFrom });

        return (
            <section className="block">
                <h2 className="subtitle">Часто задаваемые вопросы</h2>
                <Collapse accordion items={faqItems} className="text" />
            </section>
        );
    };

export default FAQ;
