import React, { useEffect } from 'react';
import { useAppDispatch } from './hooks/useAppDispatch.ts';
import { useSelector } from 'react-redux';
import { fetchCitiesSlice } from './store/slices/citySlice.ts';
import { fetchObjects } from './store/slices/objectSlice.ts';
import { RootState } from './store/store.ts';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import SantaList from "./components/SantaList.tsx";
import LoginForm from "./components/LoginForm.tsx";
import RegisterForm from "./components/RegisterForm.tsx";
import Header from "./components/Header.tsx";
import Snowflakes from "./components/Snowflakes.tsx";
import Footer from "./components/Footer.tsx";
import MainPage from "./pages/Main.tsx";
import Profile from "./pages/Profile.tsx";
import NotFound from "./pages/NotFound.tsx";


const App: React.FC = () => {
  const dispatch = useAppDispatch();
  const cities = useSelector((state: RootState) => state.cities.cities);
  const { items } = useSelector((state: RootState) => state.objects);

  useEffect(() => {
    if (cities.length === 0) {
      dispatch(fetchCitiesSlice());
    }
    if (items.length === 0) {
      dispatch(fetchObjects());
    }
  }, [dispatch, cities.length, items.length]);


  return (
    <Router>
      <div className="container">
        <Header />
        <Snowflakes />
        <div className="content">
          <Routes>
            <Route path="/логин/" element={<LoginForm />} />
            <Route path="/регистрация/" element={<RegisterForm />} />
            <Route path="/профиль/" element={<Profile />} />
            <Route path="/листинг/:city/:serviceType" element={<SantaList />} />
            <Route path="/листинг/:city" element={<SantaList />} />
            <Route path="/" element={<MainPage />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </div>
        <Footer />
      </div>
    </Router>
  );
};

export default App;
