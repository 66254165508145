import React from "react";
import { Helmet } from "react-helmet";

const SeoSchema: React.FC<{ location: string | null; priceFrom: number }> = ({
  location,
  priceFrom,
}) => {
  const schema = {
    "@context": "https://schema.org",
    "@type": "Service",
    "name": `Дед Мороз ${location}`,
    "priceRange": `${priceFrom}+ руб.`,
    "areaServed": location,
    "provider": {
    "@type": "LocalBusiness",
    "name": "Агрегатор Дедов Морозов"
  }
  };

  return (
    <Helmet>
      <script type="application/ld+json">{JSON.stringify(schema)}</script>
    </Helmet>
  );
};

export default SeoSchema;
