import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Select, Button, Row, Col, Card } from "antd";
import {
  SearchOutlined,
  ClockCircleOutlined,
  SmileOutlined,
  CustomerServiceOutlined,
} from "@ant-design/icons";
import { useSelector } from "react-redux";
import { RootState } from "../store/store";
import { Helmet } from "react-helmet-async";


const MainPage: React.FC = () => {
  const navigate = useNavigate();
  const cities = useSelector((state: RootState) => state.cities.cities);

  const Search = (value: string) => {
    navigate(`/листинг/${value}`);
  };

  useEffect(() => {
    if(document.title.length > 0){
      (window as any).YandexRotorSettings.IsLoaded = true;
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>Агрегатор Дедов Морозов</title>
        <meta
          name="description"
          content={`Найдите лучших Дедов Морозов в вашем городе`}
        />
      </Helmet>
      <div className="main-page">
        <h1 className="title">Главные по дедам морозам</h1>
        <div className="section main_first_block">
          <img
            src="/images/santa_main.webp"
            alt="Дед мороз"
            loading="lazy"
            className="main_image"
          />
          <div className="main_block_content">
            <p className="main_first_block_text">
              Закажите визит Деда Мороза и Снегурочки прямо к вам домой!
              Подарите незабываемые праздничные впечатления себе и своим
              близким. Наши профессиональные исполнители создадут волшебную
              атмосферу, наполнят ваш дом радостью и смехом, а дети получат
              подарки и чудесные воспоминания. Дед Мороз и Снегурочка уже готовы
              отправиться в путь, чтобы подарить вам настоящее новогоднее чудо!
            </p>
            <Select
              showSearch
              placeholder="Выберите ваш город"
              optionFilterProp="children"
              filterSort={(optionA, optionB) =>
                (optionA?.name ?? "")
                  .toLowerCase()
                  .localeCompare((optionB?.name ?? "").toLowerCase())
              }
              onChange={Search}
              className="search text"
            >
              {cities.map((city) => (
                <Select.Option key={city.id} value={city.name}>
                  {city.name}
                </Select.Option>
              ))}
            </Select>
          </div>
        </div>

        <div className="section text">
          <h2 className="subtitle">Услуги</h2>
          <div className="card-container">
            <div className="card">
              <img
                src="/images/santa-home.png"
                alt="Дед Мороз на дом"
                className="card-image"
                loading="lazy"
              />
              <h3 className="h3">Дед мороз на дом</h3>
              <p>
                Пригласите Деда Мороза к вам домой для создания волшебной
                атмосферы, которая останется в сердцах надолго!
              </p>
              <Button
                color="primary"
                variant="solid"
                onClick={() => navigate("/листинг/на дом")}
              >
                Заказать
              </Button>
            </div>
            <div className="card">
              <img
                src="/images/santa-kids.png"
                alt="Дед Мороз для детей"
                className="card-image"
                loading="lazy"
              />
              <h3 className="h3">Дед мороз детям</h3>
              <p>
                Организуйте визит Деда Мороза в детский сад или школу, чтобы
                подарить детям незабываемый праздник.
              </p>
              <Button
                color="primary"
                variant="solid"
                onClick={() => navigate("/листинг/детям")}
              >
                Заказать
              </Button>
            </div>
            <div className="card">
              <img
                src="/images/santa-event.png"
                alt="Дед Мороз на мероприятие"
                className="card-image"
                loading="lazy"
              />
              <h3 className="h3">Дед мороз на корпоратив</h3>
              <p>
                Устройте праздник на корпоративе или мероприятии, где Дед Мороз
                создаст атмосферу новогоднего веселья.
              </p>
              <Button
                color="primary"
                variant="solid"
                onClick={() => navigate("/листинг/на корпоратив")}
              >
                Заказать
              </Button>
            </div>
          </div>
        </div>
        <div className="section advantages">
          <h2 className="subtitle">Почему выбирают нас</h2>
          <Row gutter={[16, 16]} className="advantages-list">
            <Col xs={24} sm={12} md={8}>
              <Card bordered={false}>
                <SearchOutlined
                  style={{ fontSize: "36px", color: "#1890ff" }}
                />
                <h3 className="h3">Быстрый поиск исполнителей</h3>
                <p className="text">
                  Найдите подходящего исполнителя для вашего мероприятия всего
                  за несколько кликов.
                </p>
              </Card>
            </Col>
            <Col xs={24} sm={12} md={8}>
              <Card bordered={false}>
                <ClockCircleOutlined
                  style={{ fontSize: "36px", color: "#1890ff" }}
                />
                <h3 className="h3">Мгновенное бронирование</h3>
                <p className="text">
                  Сэкономьте время и забронируйте исполнителя без лишних
                  ожиданий.
                </p>
              </Card>
            </Col>
            <Col xs={24} sm={12} md={8}>
              <Card bordered={false}>
                <SmileOutlined style={{ fontSize: "36px", color: "#1890ff" }} />
                <h3 className="h3">Прямой контакт с исполнителем</h3>
                <p className="text">
                  Вы общаетесь напрямую с исполнителем, без посредников и
                  комиссий.
                </p>
              </Card>
            </Col>
            <Col xs={24} sm={12} md={8}>
              <Card bordered={false}>
                <ClockCircleOutlined
                  style={{ fontSize: "36px", color: "#1890ff" }}
                />
                <h3 className="h3">Удобное расписание</h3>
                <p className="text">
                  Выбирайте удобное время для вашего мероприятия, благодаря
                  гибкому графику исполнителей.
                </p>
              </Card>
            </Col>
            <Col xs={24} sm={12} md={8}>
              <Card bordered={false}>
                <SmileOutlined style={{ fontSize: "36px", color: "#1890ff" }} />
                <h3 className="h3">Прозрачные условия</h3>
                <p className="text">
                  Оплата проходит напрямую с исполнителем, без скрытых платежей
                  и наценок.
                </p>
              </Card>
            </Col>
            <Col xs={24} sm={12} md={8}>
              <Card bordered={false}>
                <CustomerServiceOutlined
                  style={{ fontSize: "36px", color: "#1890ff" }}
                />
                <h3 className="h3">Круглосуточная поддержка</h3>
                <p className="text">
                  Наша служба поддержки всегда готова помочь вам с любыми
                  вопросами и трудностями.
                </p>
              </Card>
            </Col>
          </Row>
        </div>

        <div className="section main_second_block">
          <p className="main_first_block_text text">
            В этом праздничном сезоне наши любимые Деды Морозы и Снегурочки
            готовы подарить радость и счастье вашим детям! С их волшебным
            мешком, полным ярких подарков, они посетят дома, распространяя
            веселье и счастье. Каждый подарок тщательно выбран, чтобы сделать
            этот Новый год незабываемым. Готовьтесь к праздничному празднованию,
            наполненному смехом, любовью и духом дарения!
          </p>
          <img
            src="/images/gifts.png"
            alt="Мешок с подарками"
            className="main_image"
            loading="lazy"
          />
        </div>

        <div className="section seo-text block">
          <h2 className="subtitle">
            Закажите Деда Мороза на дом в вашем городе
          </h2>
          <p className="text">
            Наш сервис позволяет легко и удобно заказать визит Деда Мороза и
            Снегурочки прямо к вам домой или на любое мероприятие. Независимо от
            города, будь то Москва, Санкт-Петербург, или любой другой, мы
            предоставляем доступ к лучшим исполнителям, чтобы сделать ваш
            праздник незабываемым. Наши профессиональные артисты обеспечат вам и
            вашим детям сказочную атмосферу. Забронируйте визит Деда Мороза уже
            сегодня и наслаждайтесь волшебством!
          </p>
        </div>
      </div>
    </>
  );
};

export default MainPage;
