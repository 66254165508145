import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import Filter from "./Filter.tsx";
import LatestSantas from "./LatestSantas.tsx";
import FAQ from "./FAQ.tsx";
import SeoArticle from "./SeoArticle.tsx";
import SeoSchema from "./SeoSchema.tsx";
import { useAppDispatch } from "../hooks/useAppDispatch.ts";
import { useSelector } from "react-redux";
import { RootState } from "../store/store";
import { setCity, setServiceType } from "../store/slices/filterSlice.ts";
import { List, Button, Rate } from "antd";
import Loading from "./Loading.tsx";
import { Santa } from "../types";
import { serviceTypes } from "./constants.ts";


const SantaList: React.FC = () => {
  const dispatch = useAppDispatch();
  const cities = useSelector((state: RootState) => state.cities.cities);
  const loading = useSelector((state: RootState) => state.cities.loading);
  const { city: cityParam, serviceType: serviceTypeParam } = useParams<{
    city: string;
    serviceType: string;
  }>();

  const city = useSelector((state: RootState) => state.filters.city);
  const serviceType = useSelector(
    (state: RootState) => state.filters.serviceType
  );

  const { items } = useSelector((state: RootState) => state.objects);
  const [santas, setSantas] = useState<Santa[]>([]);
  const [selectedCityDeclension, setSelectedCityDeclension] = useState<
    string | null
  >(null);
  const [minPrice, setMinPrice] = useState<number>(0);

  useEffect(() => {
    const getSantas = async () => {
      const filteredSantas = city
        ? items.filter((santa) => santa.city === city)
        : items;
        const shuffledSantas = [...filteredSantas].sort(() => Math.random() - 0.5);
      setSantas(shuffledSantas);
    };

    const getCities = async () => {
      const selectedCity = cities.find((c) => c.name === city);
      if (selectedCity) {
        setSelectedCityDeclension(selectedCity.declension);
      }
    };

    if (cityParam) {
      const isCity = cities.some((city) => city.name === cityParam);
      const isServiceType = serviceTypes.includes(cityParam);

      if (isCity) {
        dispatch(setCity(cityParam));
      } else if (isServiceType) {
        dispatch(setServiceType(cityParam));
      }
    }

    if (serviceTypeParam) {
      dispatch(setServiceType(serviceTypeParam));
    }

    getSantas();
    getCities();
  }, [cities, city, serviceType, cityParam, serviceTypeParam, items, dispatch]);

  useEffect(() => {
    if (santas.length > 0) {
      const minPriceValue = Math.min(...santas.map((item) => item.price));
      setMinPrice(minPriceValue);
    } else {
      setMinPrice(2000);
    }
  }, [santas]);

  const titleSuffix = selectedCityDeclension
    ? `${selectedCityDeclension} ${serviceType ? serviceType : ""}`.trim()
    : serviceType || "";

  useEffect(() => {
    if (!loading) {
      if (city && serviceType) {
        if (selectedCityDeclension) {
          (window as any).YandexRotorSettings.IsLoaded = true;
        } else {
          (window as any).YandexRotorSettings.IsLoaded = false;
        }
      } else if (city) {
        if (selectedCityDeclension) {
          (window as any).YandexRotorSettings.IsLoaded = true;
        } else {
          (window as any).YandexRotorSettings.IsLoaded = false;
        }
      } else if (serviceType) {
        (window as any).YandexRotorSettings.IsLoaded = true;
      } else {
        (window as any).YandexRotorSettings.IsLoaded = false;
      }
    }
  }, [city, selectedCityDeclension, serviceType, loading]);

  return (
    <div className="listing">
      <Helmet>
        <title>
          {city
            ? `Заказать Деда Мороза ${titleSuffix}`
            : `Заказать Деда Мороза ${serviceType ? ` ${serviceType}` : ""}`}
        </title>
        <meta
          name="description"
          content={
            city
              ? `Найдено ${santas.length} компании с Дедами Морозами ${titleSuffix} по цене от ${minPrice} рублей`
              : `Список всех Дедов Морозов ${
                  serviceType ? ` ${serviceType}` : ""
                }`
          }
        />
      </Helmet>
      <SeoSchema location={titleSuffix ? titleSuffix : serviceType} priceFrom={5000} />
      <div className="listing_top">
        <div className="block filters">
          <Filter />
        </div>
        <div className="block list">
          <h1 className="title">
            {city
              ? `Заказать Деда Мороза ${titleSuffix}`
              : `Заказать Деда Мороза ${serviceType ? ` ${serviceType}` : ""}`}
          </h1>
          {loading ? (
            <Loading />
          ) : (
            <List
              header={<h2 className="h3">Выберите Деда Мороза</h2>}
              bordered
              dataSource={santas}
              renderItem={(santa) => (
                <div className="listing_item" key={santa._id}>
                  <img
                    src={santa.image}
                    alt={santa.name}
                    className="listing_item_img"
                    loading="lazy"
                  />
                  <div className="listing_item_content text">
                    <span className="listing_item_title">{santa.name}</span>
                    <span>Город: {santa.city}</span>
                    <span>Стоимость: от {santa.price} руб.</span>
                    <div>
                      Рейтинг:
                      <Rate
                        disabled
                        defaultValue={santa.rating}
                        style={{ marginLeft: 8 }}
                      />
                    </div>
                  </div>
                  <Button
                    color="primary"
                    variant="solid"
                    className="listing_item_button"
                    onClick={() => {
                      if (window.ym) {
                        window.ym(98813178, "reachGoal", "pozvonili");
                      }
                      window.open(`tel:${santa.telephone}`, "_blank");
                    }}
                  >
                    Позвонить
                  </Button>
                </div>
              )}
            />
          )}
        </div>
      </div>
      <LatestSantas />
      <SeoArticle
        location={selectedCityDeclension}
        serviceType={serviceType}
        priceFrom={minPrice}
      />

      <FAQ location={selectedCityDeclension}
        serviceType={serviceType}
        priceFrom={minPrice}/>
    </div>
  );
};

export default SantaList;
