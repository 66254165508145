import React, { useState } from 'react';
import { Form, Input, Button, Upload, message, Select } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { UploadFile } from 'antd/es/upload/interface';
import { useSelector } from "react-redux";
import { RootState } from "../store/store.ts";
import { addSanta } from '../utils/api.ts';
import useAuthRedirect from "../hooks/useAuthRedirect.js";
import { useAppDispatch } from '../hooks/useAppDispatch.ts';
import { fetchObjects } from '../store/slices/objectSlice.ts';

const AddSanta = ({
  onAddSantaClick,
}: {
  onAddSantaClick: (value: string) => void;
}) => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const cities = useSelector((state: RootState) => state.cities.cities);
  const isAuthenticatedChecked = useAuthRedirect("/логин/");

  const handleAddSanta = async (values: any) => {

    if (fileList.length === 0) {
      message.error('Пожалуйста, загрузите картинку');
      return;
    }

    try {
      setLoading(true);
      const formData = new FormData();
      formData.append('name', values.name);
      formData.append('city', values.city);
      formData.append('price', values.price);
      formData.append('telephone', values.telephone);

      if (fileList.length > 0 && fileList[0].originFileObj) {
        formData.append('image', fileList[0].originFileObj as File);
      }

      await addSanta(formData);

      message.success('Дед мороз успешно добавлен');
      form.resetFields();
      setFileList([]);
      onAddSantaClick("1")
      dispatch(fetchObjects());
    } catch (error) {
      message.error('Произошла ошибка при добавлении деда мороза');
    } finally {
      setLoading(false);
    }
  };

  const uploadProps = {
    fileList,
    beforeUpload: (file: UploadFile) => {
      return false;
    },
    onChange: ({ fileList }: { fileList: UploadFile[] }) => {
      setFileList(fileList);
    },
  };

  return (
    <Form
      form={form}
      onFinish={handleAddSanta}
      layout="vertical"
      style={{ maxWidth: 500, margin: '0 auto' }}
    >
      <Form.Item label="Название" name="name" rules={[{ required: true, message: 'Пожалуйста, введите название' }]}>
        <Input placeholder="Служба дедов морозов" className='text' />
      </Form.Item>
      <Form.Item label="Город" name="city" rules={[{ required: true, message: 'Пожалуйста, выберите город' }]}>
        <Select
            showSearch
            placeholder="Выберите ваш город"
            optionFilterProp="children"
            className='text'
            filterSort={(optionA, optionB) =>
              (optionA?.name ?? "")
                .toLowerCase()
                .localeCompare((optionB?.name ?? "").toLowerCase())
            }

          >
            {cities.map((city) => (
              <Select.Option key={city.id} value={city.name}>
                {city.name}
              </Select.Option>
            ))}
          </Select>
      </Form.Item>
      <Form.Item label="Цена" name="price" rules={[{ required: true, message: 'Пожалуйста, введите цену' }]}>
        <Input placeholder="5000" type="number" className='text'/>
      </Form.Item>
      <Form.Item label="Телефон" name="telephone" rules={[{ required: true, message: 'Пожалуйста, введите номер телефона' }]}>
        <Input placeholder="+7999999999" className='text'/>
      </Form.Item>
      <Form.Item label="Картинка" name="image" rules={[{ required: true, message: 'Пожалуйста, загрузите картинку' }]}>
        <Upload {...uploadProps} listType="picture">
          <Button icon={<UploadOutlined />}>Загрузите картинку</Button>
        </Upload>
      </Form.Item>
      <Form.Item>
        <Button type="primary" onClick={() => handleAddSanta(form.getFieldsValue())} loading={loading} block>
          Добавить Деда Мороза
        </Button>
      </Form.Item>
    </Form>
  );
};

export default AddSanta;
