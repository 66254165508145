import React from "react";
import { Button, Result } from "antd";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";

const NotFound: React.FC = () => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <>
      <Helmet>
        <title>Агрегатор Дедов Морозов</title>
        <meta
          name="description"
          content={`Найдите лучших Дедов Морозов в вашем городе`}
        />
      </Helmet>
      <Result
        status="404"
        title="404"
        subTitle="К сожалению, страница, которую вы посетили, не существует."
        extra={
          <Button type="primary" onClick={handleGoBack}>
            Вернуться назад
          </Button>
        }
      />
    </>
  );
};

export default NotFound;
